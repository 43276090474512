.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.report-issue {
  padding-left: 10px;
  color: red;
  font-size: xx-large;
}

#root, .viewDiv {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  letter-spacing: 0em;
  line-height: 1.55rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.f1{
  font-size: 0.9em;
}

.f2{
  font-size: 1.0em;
  font-weight: 600;
  color: rgb(66, 125, 236);
}

.f3{
  font-size: 1.2em;
  padding: 4px;
}

.header{
  font-size: 1.0em;
  font-weight: 600;
}

.app-background{
  width: 100%;
}

.card:hover {
  background-color:rgb(232, 232, 232);
}

.card {
  border-radius: 5px;
  position: relative;
  top: 0;
  left: 0;
  padding: 20px;
  margin-bottom: 20px;
  -webkit-box-shadow: 4px 4px 4px 0px rgba(179,179,179,1);
  -moz-box-shadow: 4px 4px 4px 0px rgba(179,179,179,1);
  box-shadow: 4px 4px 4px 0px rgba(179,179,179,1);
  box-sizing: border-box;
  cursor: pointer;
}

.list-group-item.active {
  background-color: #ababad !important;
  border-color:  #ababad !important;
}

.modal-dialog{
  min-width:60%
}

.imageContainer{
  position: relative;
}

.imageBackground{
  top:0;
  left:0;
}

.popupContainer{
  position: absolute;
  top:0;
  right:5;
  text-align:left;
}

.imageOverlay{
  position: absolute;
  top:0;
  left:0;
}

.jobimage img {
  max-width: 600px;
  max-height: 100%;
}

.fullwidth {
  position:absolute;
  left:0;
  right:0;
}

.maptoggle{
  position:absolute;
  left:90px;
  top:15px;
  z-index:999;
}

.edittoggle{
  position:absolute;
  left:250px;
  top:15px;
  z-index:999;
}

.zoomInOut{
  position:absolute;
  left:20px;
  top:10px;
  z-index:999;
}

.zoombutton{
  color: #fff;
  background-color: #2E4C80;
  border-color: #2E4C80;
}

.form{
  height: 100%;
  padding:20px;
  background-color: rgba(211, 211, 211, 0.3);
}

.fa-circle path{
  stroke: lightgray;
  stroke-width: 30px;
}

div.modal-title.h4{
  font-size: 1.0em !important;
}

.dropdown-menu {
  right: 0 !important;
  left: auto !important;
}

.pattern {
  color:red !important;
}

.static {
  cursor: not-allowed;
}
.draggable {
  cursor: move;
}

.paging{
  color:gray
}

.paging:hover{
  color:darkgray
}

/* WARNING Required to fix broken Esri Styling */
.esri-features.esri-widget.esri-widget--panel {
  overflow: hidden;
  }